@font-face {
  font-family: "DouyinMeihao"; /* 自定义字体名称 */
  src: url("../../assets/DouyinSansBold.otf") format("opentype"); /* 替换为您的文件路径 */
  font-weight: 700; /* 根据需要设置字体粗细 */
  font-size: 2.5rem;
  line-height: 2.5rem;
}

.homeHeaderMain {
  background-color: #fff !important;
}

.headerMain {
  padding: 1rem 8rem;
  background-color: #040000;
  display: flex;
  justify-content: space-between;
  .headerLeft {
    cursor: pointer;
    display: flex;
    line-height: 6rem;
    .headerLogo {
      width: 4.2rem;
      height: 4.2rem;
      margin: auto 0.8rem auto 0;
      flex-shrink: 0;
    }
    .headerTitle {
      font-family: "DouyinMeihao", sans-serif;
      font-size: 2.4rem;
      font-weight: 700;
      flex-shrink: 0;
      color: #fff;
      height: 4.2rem;
      line-height: 4.9rem;
    }
    .homeHeader {
      color: #333;
    }
  }

  .headerCenter {
    width: 60rem;
    height: 4.2rem;
    margin: auto 0;
    background: rgba(245, 245, 245, 0.3);
    border-radius: 32px;
    flex: 0.6;
    display: flex;
    justify-content: space-between;
    .headerCenterInput {
      width: 80%;
      height: 4.2rem !important;
      box-shadow: unset !important;
      background: transparent !important;
      border: none !important;
      border-radius: 32px !important;
      font-size: 1.4rem !important;
      color: #fff !important;
      &::placeholder {
        color: #b9b9b9 !important;
      }
    }

    .headerSearchBtn {
      cursor: pointer;
      display: flex;
      background: #f2423e;
      align-items: center;
      border-radius: 27px;
      padding: 10px 16px;
      .headerSearchBtnIcon {
        display: block;
        width: 1.6rem;
        height: 1.6rem;
        margin: auto 8px auto 0;
      }
      .headerSearchBtnText {
        font-weight: 600;
        font-size: 1.6rem;
        color: #fff;
      }
    }
  }

  .headerCenterHome {
    background-color: #f5f5f5;
    .headerCenterInputHome {
      color: #222 !important;
      &::placeholder {
        color: #b9b9b9 !important;
      }
    }
  }

  .headerRight {
    display: flex;
    justify-content: space-between;
    .headerRightBtn {
      display: flex;
      align-items: center;
      padding: 10px 16px;
      justify-content: center;
      background: #ffdcb4;
      border-radius: 50px;
      font-weight: 600;
      color: #943806;
      cursor: pointer;
      margin-right: 2rem;

      &:hover {
        background: #ffc98b;
      }
      .headerRightIcon {
        width: 1.8rem;
        height: 1.8rem;
        margin: auto 0.8rem auto 0;
      }

      .headerRightText {
        font-size: 1.4rem;
        flex-shrink: 0;
        color: #943806;
      }
    }
    .headerRightEnd {
      cursor: pointer;
      display: flex;
      .homeHeaderRightUnlogin {
        color: #333 !important;
      }

      .headerRightUnlogin {
        font-size: 1.6rem;
        color: #fff;
        margin: auto 0;
      }

      .custom-menu-item {
        background-color: transparent !important; /* 去掉背景色 */
      }

      .custom-menu-item:hover {
        background-color: transparent !important; /* 悬浮时保持透明 */
      }

      .headerRightLoginIcon {
        width: 4rem;
        height: 4rem;
        margin: auto 0;
        border-radius: 50%;
      }
    }
  }
}

.loginMain {
  .loginTitle {
    font-size: 2.6rem;
    font-weight: 700;
    color: #333;
    cursor: default;
  }

  .loginNoAccount {
    font-size: 1.4rem;
    color: #999;
    margin-top: -2.2rem;
    margin-bottom: 2.8rem;
    display: flex;
    cursor: default;
    .loginNoAccountSpec {
      color: #333;
      cursor: pointer;
      &:hover {
        color: #f30;
      }
    }
  }

  .loginPicContainer {
    position: relative;
    width: 16rem;
    height: 16rem;
    display: block;
    text-align: center;
    margin: 0 auto 4rem auto;
    .loginPic {
      width: 100%;
      height: 100%;
    }
    .loginPicInvalid {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      background-color: #0009;
      .invalidText {
        font-size: 18px;
        color: #fff;
        cursor: default;
      }

      .invalidBtn {
        cursor: pointer;
        width: 8.8rem;
        height: 3rem;
        text-align: center;
        line-height: 3rem;
        color: #fff;
        margin-top: 2rem;
        border-radius: 4px;
        background-color: #3bc66f;
      }
    }
  }

  .loginPicInvalid {
  }

  .loginPicTitle {
    cursor: default;
    display: flex;
    justify-content: center;
    .loginPicIcon {
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 0.4rem;
    }

    .loginPicText {
      font-size: 1.4rem;
    }
  }

  .loginInput {
    margin-bottom: 1.6rem;
    width: 100%;
    height: 5rem;
    font-size: 1.4rem;
    color: #333;
    text-indent: 2rem;
    background-color: #fff;
    border-radius: 10px;
    border: solid 1px #dddddd;
    &::placeholder {
      color: #999 !important;
    }
    &:focus {
      border: solid 1px #333;
      box-shadow: none !important; /* 禁用默认的阴影效果 */
    }
  }

  .forgetPassword {
    text-align: right;
    color: inherit;
    cursor: pointer;
    margin-bottom: 1.6rem;
  }

  .loginBtn {
    width: 100%;
    height: 5rem;
    color: #f0f0f0;
    text-align: center;
    line-height: 5rem;
    border-radius: 10px;
    background: linear-gradient(90deg, #ff541f, #ff2323);
    margin-bottom: 2.2rem;
    cursor: pointer;
    &:hover {
      background: linear-gradient(90deg, #ff6917, #ff3000);
    }
  }

  .loginTypeBtnGroup {
    display: flex;
    justify-content: center;
    border-top: 1px solid #eee;
    padding-top: 2rem;
    margin-bottom: 2rem;
    margin-top: 3rem;
    .loginTypeBtn {
      display: flex;
      cursor: pointer;
      .loginTypeBtnIcon {
        width: 2.4rem;
        height: 2.4rem;
      }

      .loginTypeBtnText {
        margin-left: 1rem;
        display: flex;
        align-items: center;
        &:hover {
          color: #f30;
        }
      }
    }
  }

  .loginFooter {
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    color: #bbb;
    font-size: 1.4rem;
    margin-bottom: 2.8rem;
    cursor: default;
  }
}

.custom-dropdown-menu {
  width: 13rem;
  text-align: center;
}
