.detailContainer {
  width: 100%;
  min-height: calc(100% - 16.5rem);
  position: relative;
  .detailHeader {
    width: calc(100% - 16rem);
    padding: 2rem 8rem;
    text-align: center;
    background: #fff;
    box-shadow: 0 12px 20px 0 hsla(0, 0%, 84%, 0.5);
    .mainTitle {
      font-size: 2.4rem;
      font-weight: 800;
      margin-bottom: 2rem;
    }
    .subTitle {
      display: flex;
      justify-content: center;
      color: rgb(204, 204, 204);
      font-size: 1.4rem;
      .createTime {
        margin-right: 4rem;
        display: flex;
        .createTimeLabel {
          margin-right: 0.8rem;
        }
      }
      .watched {
        margin-right: 3rem;
        display: flex;
        align-items: end;
        .watchedIcon {
          width: 14px;
          height: 13px;
          margin-right: 0.8rem;
          background-repeat: no-repeat;
          background-image: url("../../assets/spritPic2.png");
          background-position: -260px -118px;
        }
      }
      .collected {
        display: flex;
        align-items: baseline;
        .collectedIcon {
          width: 14px;
          height: 13px;
          margin-right: 0.8rem;
          background-repeat: no-repeat;
          background-image: url("../../assets/spritPic2.png");
          background-position: -278px -116px;
        }
      }
    }
  }

  .rightNav {
    position: fixed;
    top: 30rem;
    right: 4%;
    z-index: 1;
    .rightNavItem {
      width: 4rem;
      height: 4rem;
      margin-bottom: 1rem;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 淡灰色阴影 */
      display: flex; /* 使用flex布局 */
      align-items: center; /* 垂直居中 */
      justify-content: center; /* 水平居中 */
      cursor: pointer; /* 鼠标指针样式 */
      overflow: hidden;
      .rightNavItemPic {
        width: 100%;
        height: 100%;
        overflow: hidden;
        object-fit: cover;
      }

      .rightNavItemIcon {
        width: 2rem;
        height: 2rem;
      }
      .rightNavItemIcon2 {
        width: 2rem;
        height: 1.4rem;
      }
      .rightNavItemIcon3 {
        width: 2.2rem;
        height: 2.2rem;
      }
    }
  }

  .imgContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 50rem;
    padding-top: 4rem;
    .imgItem {
      width: 60%;
      margin-bottom: 1.8rem;
      overflow: hidden;
      object-fit: cover;
      position: relative;
      .imgItemPic {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .downloadTips {
        visibility: hidden; /* 默认隐藏 */
        width: 11rem;
        height: 3.2rem;
        background: rgba(0, 0, 0, 0.6);
        cursor: pointer;
        position: absolute;
        left: 1rem;
        top: 1rem;
        font-size: 1.4rem;
        color: #fff;
        line-height: 3.2rem;
        text-align: center;
        transition: visibility 0.2s ease-in-out; /* 提供平滑的显示效果 */
      }

      &:hover .downloadTips {
        visibility: visible; /* 鼠标悬浮时显示 */
      }
    }
  }

  .noMorePic {
    margin: 3rem auto 0 auto;
    display: flex;
    justify-content: space-between;
    .splitLine {
      height: 1px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.2);
      line-height: 1.6rem;
      margin-top: 0.6rem;
    }
  }

  .detailFooter {
    padding: 5rem 12rem;
    background: #fff;
    .footerTitle {
      font-size: 2.4rem;
      font-weight: 800;
      margin-bottom: 3.4rem;
    }

    .detailFooterImgContainer {
      display: grid;
      grid-template-columns: repeat(
        5,
        minmax(10rem, 1fr)
      ); /* 根据页面宽度自适应 n 列 */
      gap: 1.6rem; /* 图片之间的间距 */
      .footerItem {
        .imgContainerFooter {
          width: 100%;
          height: 24rem;
          cursor: pointer;
          overflow: hidden; /* 保证图片放大时不会超出容器范围 */
          position: relative;
          border-radius: 12px;
          .footerItemPic {
            width: 100%;
            height: 100%;
            object-fit: cover; /* 保持图片比例 */
            transition: transform 1s ease-in-out; /* 定义放大动画的过渡效果 */
          }

          .collectIcon1 {
            display: none;
          }

          &:hover {
            .footerItemPic {
              transform: scale(1.1); /* 鼠标悬浮时图片放大1.1倍 */
            }
            .collectIcon1 {
              display: block;
              position: absolute;
              z-index: 1;
              width: 3.4rem;
              height: 3.4rem;
              line-height: 3.6rem;
              right: 1.2rem;
              bottom: 1.4rem;
              background: hsla(0, 0%, 100%, 0.8);
              background-size: 4rem 4rem;
              font-size: 2rem;
              border-radius: 50%;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              .collectIconPic {
                width: 2rem;
                height: 2rem;
                border-radius: 0px;
                background: transparent;
                z-index: 1;
              }
            }
            &::after {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 24rem;
              background: rgba(34, 34, 34, 0.4);
            }
          }
        }

        .footerItemTitle {
          font-size: 1.4rem;
          color: rgba(34, 34, 34, 0.8);
          margin: 1.4rem 0;
        }
      }
    }
  }
}
.downloadModal {
  .ant-modal-content {
    padding: 0 !important;
    .ant-modal-header {
      padding: 20px 24px 0 24px !important;
    }
  }
}

.line {
  width: 100%;
  height: 1px;
  background-color: rgba(153, 153, 153, 0.7);
}

.downloadModalContainer {
  padding: 20px 8rem 20px 8rem !important;
  .flexContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    .leftPic {
      position: relative;
      width: 16rem;
      height: 16rem;
      .leftPicPic {
        width: 100%;
        height: 100%;
      }
      .leftPicInvalid {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        background-color: #0009;
        .invalidText {
          font-size: 18px;
          color: #fff;
          cursor: default;
        }

        .invalidBtn {
          cursor: pointer;
          width: 8.8rem;
          height: 3rem;
          text-align: center;
          line-height: 3rem;
          color: #fff;
          margin-top: 2rem;
          border-radius: 4px;
          background-color: #3bc66f;
        }
      }
    }

    .rightTextContainer {
      margin-top: 3rem;
      width: 50%;
      .rightLine1 {
        display: flex;
        line-height: 3.6rem;
        margin-bottom: 3rem;
        .label {
          font-size: 2rem;
          color: #333;
        }
        .price {
          color: #e97017;
          font-size: 1.6rem;
        }

        .priceCount {
          font-size: 3.6rem;
          font-weight: 500;
        }
      }

      .rightLine2 {
        display: flex;
        line-height: 2.4rem;
        color: #333;
        font-size: 1.6rem;
        .label {
          margin-right: 1rem;
        }

        .rightIcon {
          width: 2.4rem;
          height: 2.4rem;
          margin-right: 1rem;
        }
      }
    }
  }

  .bottomTips {
    font-size: 1.4rem;
    color: #999;
    font-weight: 500;
    text-align: center;
  }
}
