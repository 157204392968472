.overViewMain {
  padding: 4rem;
  height: 100%;
  box-sizing: border-box;
  .overViewTitle {
    font-size: 2.4rem;
    line-height: 2.4rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .overViewContainer {
    margin-top: 6rem;
  }
}
