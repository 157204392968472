.footerMain {
  width: calc(100% - 16rem);
  padding: 3.2rem 8rem;
  background-color: #040000;
  .footerTop {
    display: flex;
    color: #999;
    font-size: 1.4rem;
    margin-bottom: 2rem;
    .footerTopItem {
      margin-right: 4rem;
      font-weight: 600;
    }
    .hover {
      cursor: pointer;
      &:hover {
        color: #ffffff;
      }
    }
  }

  .footerBottom {
    display: flex;
    color: #999;
    font-size: 1.4rem;
    .footerBottomItem {
      margin-right: 1.2rem;
    }
    .footerBottomItemHover {
      cursor: pointer;
      &:hover {
        color: #fff;
      }
    }
    .footerBottomSplit {
      margin-right: 1.2rem;
    }
  }
}
