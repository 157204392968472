.settingMain {
  height: calc(100% - 15rem);
  padding: 11rem 5rem;
  border-radius: 10px;
  background-color: #ffffff;

  .account-settings {
    .setting-item {
      display: flex;
      align-items: center;
      margin-bottom: 4rem;

      label {
        flex: 0 0 15rem; /* 固定 label 宽度 */
        text-align: right;
        margin-right: 2rem; /* 设置 label 和内容区域的间距 */
        font-size: 1.4rem;
        color: #999;
      }

      /* 右侧内容区域 */
      .edit-section,
      .avatar-section,
      div {
        flex-grow: 1; /* 让内容区域占据剩余空间 */
        display: flex;
        align-items: center;
        justify-content: flex-start; /* 左对齐 */
        font-size: 1.4rem;
        color: #333;
      }

      .avatar-section {
        .avatar-img {
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
          margin-right: 1rem;
        }
      }

      .edit-section {
        input {
          margin-right: 1rem;
          flex-grow: 1; /* 让输入框占据剩余空间 */
        }

        button {
          margin-left: 1rem;
        }

        .cancelBindBtn {
          background: linear-gradient(90deg, #ff541f, #ff2323);
          border: 0;
          color: #fff;
        }
      }
    }
  }
}

.phoneModalContainer {
  border-top: 1px solid #eeeeee;
  padding: 3rem 4rem 2rem;
  .input-container {
    height: 5rem;
    margin-bottom: 2rem;
    .full-width-input {
      height: 5rem;
    }
  }

  .code-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    .code-input {
      width: 60%;
      display: block;
      height: 5rem;
    }

    .send-code-button {
      height: 5rem;
      width: 38%;
      display: block;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: center; /* 居中对齐 */
    margin-top: 2rem;
    .modalBtn1 {
      margin: 0 2rem;
      height: 5rem;
      width: 10rem;
    }
  }
}

.bindWechatMain {
  .bindWechatTitle {
    text-align: center;
    font-size: 2.6rem;
    font-weight: 700;
    color: #333;
    cursor: default;
  }

  .bindWechatPicContainer {
    position: relative;
    width: 16rem;
    height: 16rem;
    display: block;
    text-align: center;
    margin: 0 auto 4rem auto;
    .bindWechatPic {
      width: 100%;
      height: 100%;
    }
    .bindWechatPicInvalid {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      background-color: #0009;
      .invalidText {
        font-size: 18px;
        color: #fff;
        cursor: default;
      }

      .invalidBtn {
        cursor: pointer;
        width: 8.8rem;
        height: 3rem;
        text-align: center;
        line-height: 3rem;
        color: #fff;
        margin-top: 2rem;
        border-radius: 4px;
        background-color: #3bc66f;
      }
    }
  }

  .bindWechatPicTitle {
    cursor: default;
    display: flex;
    justify-content: center;
    .bindWechatPicIcon {
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 0.4rem;
    }

    .bindWechatPicText {
      font-size: 1.4rem;
    }
  }
}


.cancelContainer {
  .confirmText {
    height: 1.5rem;
    line-height: 1.5rem;
    text-align: center;
    margin: 5rem auto;
  }
  .modal-footer {
    display: flex;
    justify-content: center; /* 居中对齐 */
    margin-top: 2rem;
    .modalBtn1 {
      margin: 0 2rem;
      height: 5rem;
      width: 10rem;
    }
  }
}
