.adminManagerMain {
  padding: 4rem;
  height: 100%;
  box-sizing: border-box;
  .adminManagerTitle {
    font-size: 2.4rem;
    line-height: 2.4rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }
}

.adminModalBtn {
  margin: 0 auto;
  height: 5rem;
  width: 10rem;
  background-color: #f30;
  &:hover {
    background-color: #f30 !important;
  }
}
.adminModalBtnAdd {
  margin: 0 auto;
  height: 4rem;
  width: 8rem;
  background-color: #f30;
  margin-bottom: 2rem;
  &:hover {
    background-color: #f30 !important;
  }
}

.adminModalLine {
  display: flex;
  justify-content: space-between;
  .adminModalInput {
    height: 5rem;
    margin: 1rem 0 4rem 0;
    flex:1;
  }

  .adminModalLabel {
    flex-shrink: 0;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 7rem;
  }
}
