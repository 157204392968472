.adminMain {
  position: relative;
  height: 100%;
  background-color: #f6f6f6;
  .adminHeader {
    padding: 1rem 8rem;
    background-color: #040000;
    .headerLeft {
      cursor: pointer;
      display: flex;
      line-height: 6rem;
      .headerLogo {
        width: 4.2rem;
        height: 4.2rem;
        margin: auto 0.8rem auto 0;
        flex-shrink: 0;
      }
      .headerTitle {
        font-size: 2.4rem;
        flex-shrink: 0;
        color: #fff;
        font-weight: 700;
      }
    }
  }

  .adminContainer {
    padding: 4rem 8rem;
    height: calc(100% - 16rem);
    display: flex;
    justify-content: space-between;
    .leftNav {
      background-color: #fff;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      flex-shrink: 0;
      margin-right: 2rem;
      .navItem {
        text-align: center;
        padding: 2rem 3rem;
        color: #333;
        font-size: 1.4rem;
        cursor: pointer;
        position: relative;
        &:hover {
          color: #fff;
          background-color: #f30;
        }
      }

      .selected {
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 2px;
          height: 100%;
          background-color: #f30;
          transition: width 0.2s ease-in-out;
        }
        background-color: #fff; /* 可选：选中行背景色 */
      }
    }

    .rightContainer {
      flex: 1;
      border-radius: 12px;
      background-color: #fff;
      height: 100%;
      overflow-y: auto;
    }
  }
}
