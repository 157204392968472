html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Microsoft YaHei, SimSun, "\5b8b\4f53", sans-serif;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

@media screen and (min-width: 1441px) {
  html {
    font-size: 10px;
  }
}

@media screen and (max-width: 1440px) and (min-width: 769px) {
  html {
    font-size: 8px;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 6px;
  }
}

::-webkit-scrollbar {
  width: 1.2rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #ccc;
}

::-webkit-scrollbar-track-piece {
  background-color: #f6f6f6;
  border-radius: 6px;
}
