.collectMain {
  height: 100%;
  .collectPicContainer {
    height: calc(100% - 5rem);
    /* 图片展示区域布局 */
    display: grid;
    grid-template-columns: repeat(
      5,
      minmax(10rem, 1fr)
    ); /* 根据页面宽度自适应 n 列 */
    gap: 1.6rem; /* 图片之间的间距 */

    .imageItem {
      .image-grid {
        width: 100%;
        height: 22rem;
        cursor: pointer;
        overflow: hidden; /* 保证图片放大时不会超出容器范围 */
        position: relative;
        border-radius: 12px;
        &:hover {
          img {
            transform: scale(1.1); /* 鼠标悬浮时图片放大1.1倍 */
          }

          .collectIcon {
            display: block;
            position: absolute;
            z-index: 1;
            width: 3.4rem;
            height: 3.4rem;
            line-height: 3.6rem;
            right: 1.2rem;
            bottom: 1.4rem;
            background: hsla(0, 0%, 100%, 0.8);
            background-size: 4rem 4rem;
            font-size: 2rem;
            border-radius: 50%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            .collectIconPic {
              width: 2rem;
              height: 2rem;
              border-radius: 0px;
              background: transparent;
            }
          }
          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(34, 34, 34, 0.4);
          }
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover; /* 保持图片比例 */
          transition: transform 1s ease-in-out; /* 定义放大动画的过渡效果 */
        }
      }
    }

    .picTitle {
      margin-top: 0.8rem;
      height: 2rem;
      font-size: 1.4rem;
      color: rgba(34, 34, 34, 0.8);
      line-height: 2rem;
      cursor: pointer;
      &:hover {
        color: #000;
        font-weight: 600;
      }
    }
  }

  .paginationContainer {
    margin: 1.6rem auto;
    display: table;
    & > .ant-pagination-item {
      border: 1px solid #999;
      border-radius: 8px;
    }

    & > .ant-pagination-item-active {
      border: transparent;
      background-color: #f30;
      color: #fff;
      a {
        color: #fff;
      }
    }

    & > .ant-pagination-prev {
      border: 1px solid #999;
      border-radius: 8px;
      padding: 0 0.6rem;
      a {
        color: #000;
      }
    }
    & > .ant-pagination-next {
      border: 1px solid #999;
      border-radius: 8px;
      padding: 0 0.6rem;
      a {
        color: #000;
      }
    }
  }
}
