.examineMain {
  padding: 4rem;
  height: 100%;
  box-sizing: border-box;
  .examineTitle {
    font-size: 2.4rem;
    line-height: 2.4rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .paginationContainer {
    margin: 1.6rem auto;
    display: table;
    & > .ant-pagination-item {
      border: 1px solid #999;
      border-radius: 8px;
    }

    & > .ant-pagination-item-active {
      border: transparent;
      background-color: #f30;
      color: #fff;
      a {
        color: #fff;
      }
    }

    & > .ant-pagination-prev {
      border: 1px solid #999;
      border-radius: 8px;
      padding: 0 0.6rem;
      a {
        color: #000;
      }
    }
    & > .ant-pagination-next {
      border: 1px solid #999;
      border-radius: 8px;
      padding: 0 0.6rem;
      a {
        color: #000;
      }
    }
  }
}

.modalBtn {
  margin: 0 auto;
  height: 5rem;
  width: 10rem;
  background-color: #f30;
  &:hover {
    background-color: #f30 !important;
  }
}

.modalInput {
  height: 5rem;
  margin: 6rem 0 4rem 0;
}
