.personalMain {
  min-height: calc(100% - 12.2rem);
  background-color: #ffffff;
  position: relative;
  .diytooltip {
    z-index: 2;
    position: fixed;
    right: 10%;
    top: 12rem;
    opacity: 0;
    transform: translateX(-20px);
    transition: opacity 0.5s, transform 0.5s;
    pointer-events: none; /* 防止鼠标交互 */
    background: #ffffff;
    box-shadow: 0 0 20px #0000000d;
    border-radius: 10px;
    padding: 3rem 4rem;
    line-height: 1;
    .tipTitle {
      font-weight: 700;
      font-size: 1.6rem;
      color: #261a1a;
    }

    .tipSubTitle {
      margin-top: 1.3rem;
      margin-bottom: 2.5rem;
      font-size: 1.2rem;
      color: #999;
    }

    .tipErweima {
      width: 17rem;
      height: 17rem;
    }

    .tipFooter {
      margin-top: 1.8rem;
      font-weight: 300;
      font-size: 1.4rem;
      color: #bbb;
      text-align: center;
    }
  }
  .tipvisible {
    opacity: 1;
    transform: translateX(0); /* 滑动到原位置 */
    pointer-events: auto; /* 允许鼠标交互 */
  }
  .rightNavigator {
    position: fixed;
    top: 25rem;
    right: 2%;
    z-index: 1;
    .rightNavigatorItem1 {
      width: 4rem;
      height: 4rem;
      margin-bottom: 1rem;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 淡灰色阴影 */
      display: flex; /* 使用flex布局 */
      align-items: center; /* 垂直居中 */
      justify-content: center; /* 水平居中 */
      cursor: pointer; /* 鼠标指针样式 */
      overflow: hidden;
      .connectUs {
        width: 2.2rem;
        height: 2.2rem;
        background-repeat: no-repeat;
        background-image: url("../../assets/spritPic2.png");
        background-position: -93px -51px;
      }
      &:hover {
        background: linear-gradient(90deg, #ff541f, #ff3300);
        .connectUs {
          background-position: -93px -77px;
        }
      }
    }

    .rightNavigatorItem {
      width: 4rem;
      height: 4rem;
      margin-bottom: 1rem;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 淡灰色阴影 */
      display: flex; /* 使用flex布局 */
      align-items: center; /* 垂直居中 */
      justify-content: center; /* 水平居中 */
      cursor: pointer; /* 鼠标指针样式 */
      overflow: hidden;
      .rightNavItemIcon2 {
        width: 2.2rem;
        height: 2.2rem;
        background-repeat: no-repeat;
        background-image: url("../../assets/spritPic2.png");
        background-position: -143px -50px;
      }
    }
  }
  .personHeader {
    padding: 4rem 8rem;
    background: rgba(34, 34, 34, 0.8);
    display: flex;
    justify-content: space-between;
    .headerLeft {
      display: flex;
      align-items: flex-end; /* 使右侧文字与图片底部对齐 */
      .avatar {
        width: 12rem;
        height: 12rem;
        border-radius: 50%;
        margin-right: 2rem;
      }

      .userName {
        height: 2.8rem;
        font-size: 2rem;
        font-weight: 500;
        color: #fff;
        line-height: 2.8rem;
      }
    }

    .headerRight {
      display: flex;
      align-items: flex-end; /* 使右侧文字与图片底部对齐 */
      font-size: 1.4rem;
      color: #e9e9e9;
      .leftContainer {
        display: flex;
        .label {
          margin-right: 0.6rem;
        }
      }
      .splitLine {
        width: 1px;
        height: 1rem;
        background: hsla(0, 0%, 95%, 0.2);
        margin-left: 2rem;
        margin-right: 2rem;
      }
      .rightContainer {
        display: flex;
        .label {
          margin-right: 0.6rem;
        }
      }
    }
  }

  .personalContainer {
    .picContainer {
      padding: 4rem 8rem;
      height: calc(100% - 20rem);
      display: grid;
      grid-template-columns: repeat(
        5,
        minmax(10rem, 1fr)
      ); /* 根据页面宽度自适应 n 列 */
      gap: 1.6rem; /* 图片之间的间距 */

      .image-grid {
        width: 100%;
        height: 24rem;
        cursor: pointer;
        overflow: hidden; /* 保证图片放大时不会超出容器范围 */
        position: relative;
        border-radius: 12px;
        &:hover {
          img {
            transform: scale(1.1); /* 鼠标悬浮时图片放大1.1倍 */
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover; /* 保持图片比例 */
          transition: transform 1s ease-in-out; /* 定义放大动画的过渡效果 */
        }
      }

      .picTitle {
        margin-top: 0.8rem;
        height: 2rem;
        font-weight: 400;
        font-size: 1.4rem;
        color: rgba(34, 34, 34, 0.8);
        line-height: 2rem;
        cursor: pointer;
        &:hover {
          color: #000;
          font-weight: 600;
        }
      }
    }

    .paginationContainer {
      margin: 1.6rem auto;
      display: table;
      & > .ant-pagination-item {
        border: 1px solid #999;
        border-radius: 8px;
      }

      & > .ant-pagination-item-active {
        border: transparent;
        background-color: #f30;
        color: #fff;
        a {
          color: #fff;
        }
      }

      & > .ant-pagination-prev {
        border: 1px solid #999;
        border-radius: 8px;
        padding: 0 0.6rem;
        a {
          color: #000;
        }
      }
      & > .ant-pagination-next {
        border: 1px solid #999;
        border-radius: 8px;
        padding: 0 0.6rem;
        a {
          color: #000;
        }
      }
    }
  }
}
