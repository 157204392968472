.profitMain {
  height: calc(100% - 10rem);
  border-radius: 10px;
  .profitHeader {
    display: flex;
    padding: 4rem 8rem 2rem 8rem;

    justify-content: space-between;
    background: linear-gradient(
      0deg,
      rgba(254, 254, 253, 0.3) 0%,
      rgba(253, 238, 212, 0.3) 100%
    );

    .profitValue {
      .profitValContainer {
        display: flex;
        justify-content: center;
        font-size: 1.4rem;
        color: #666;
        margin-bottom: 1.6rem;
        line-height: 3rem;
        .profitVal {
          font-weight: 700;
          font-size: 2.4rem;
          color: #e80000;
        }
      }

      .btnGroup {
        display: flex;
        .btnLeft {
          width: 11.4rem;
          height: 3.4rem;
          background: linear-gradient(90deg, #ff541f 0%, #ff2323 100%);
          border-radius: 5px;
          font-size: 1.4rem;
          color: #fff;
          margin-right: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          cursor: pointer;
          .btnLeftText {
            margin-right: 0.6rem;
          }
        }

        .btnLeftDisabled {
          background: #999;
        }

        .btnRight {
          width: 11.4rem;
          height: 3.4rem;
          border-radius: 5px;
          border: 1px solid #ff3300;
          font-size: 1.4rem;
          color: #f30;
          line-height: 3.4rem;
          background-color: transparent;
          cursor: pointer;
          text-align: center;
        }
      }
    }

    .itemLabel {
      font-size: 1.4rem;
      color: #666;
    }

    .itemValue {
      font-weight: 700;
      font-size: 2rem;
      color: #e80000;
    }

    .profitYesterday {
      display: flex;
      align-items: center;
    }

    .profitMonth {
      display: flex;
      align-items: center;
    }

    .profitTotal {
      display: flex;
      align-items: center;
    }
  }

  .custom-table {
    padding: 0 5rem;
    min-height: 10rem;
    .ant-table-thead > tr > th {
      background-color: #fff3e6; // 设置表头橘黄色背景
      color: #333;
      font-weight: bold;
      text-align: center; // 居中对齐
    }

    .ant-table-tbody > tr > td {
      padding: 1.6rem; // 调整单元格内边距
    }

    .ant-table {
      border: none; // 移除表格边框
    }

    .ant-table-container {
      min-height: 20rem;
      border: none; // 移除表格内容的边框
    }

    .ant-table-cell {
      border-bottom: none; // 移除单元格之间的分割线
    }

    .ant-table-tbody > tr:hover > td {
      background-color: #f9f9f9; // 表格行hover效果
    }
  }

  .paginationContainer {
    margin: 7rem auto 2rem auto;
    display: table;
    & > .ant-pagination-item {
      border: 1px solid #999;
      border-radius: 8px;
    }

    & > .ant-pagination-item-active {
      border: transparent;
      background-color: #f30;
      color: #fff;
      a {
        color: #fff;
      }
    }

    & > .ant-pagination-prev {
      border: 1px solid #999;
      border-radius: 8px;
      padding: 0 0.6rem;
      a {
        color: #000;
      }
    }
    & > .ant-pagination-next {
      border: 1px solid #999;
      border-radius: 8px;
      padding: 0 0.6rem;
      a {
        color: #000;
      }
    }
  }
}
