.adminLoginMain {
  position: relative;
  height: 100%;
  padding: 20rem 40%;
  background-color: #f6f6f6;
  .loginHeader {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 10rem;
    text-align: center;
  }

  .loginAccountInput {
    margin-bottom: 5rem;
    width: 100%;
    height: 5rem;
    font-size: 1.4rem;
    color: #333;
    text-indent: 2rem;
    background-color: #fff;
    border-radius: 10px;
    border: solid 1px #dddddd;
    &::placeholder {
      color: #999 !important;
    }
    &:focus {
      border: solid 1px #333;
      box-shadow: none !important; /* 禁用默认的阴影效果 */
    }
  }
  .loginPasswordInput {
    margin-bottom: 5rem;
    width: 100%;
    height: 5rem;
    font-size: 1.4rem;
    color: #333;
    text-indent: 2rem;
    background-color: #fff;
    border-radius: 10px;
    border: solid 1px #dddddd;
    &::placeholder {
      color: #999 !important;
    }
    &:focus {
      border: solid 1px #333;
      box-shadow: none !important; /* 禁用默认的阴影效果 */
    }
  }
  .loginSubmitBtn {
    width: 100%;
    height: 5rem;
    color: #f0f0f0;
    text-align: center;
    line-height: 5rem;
    border-radius: 10px;
    background: linear-gradient(90deg, #ff541f, #ff2323);
    margin-bottom: 2.2rem;
    font-size: 1.6rem;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      background: linear-gradient(90deg, #ff6917, #ff3000);
    }
  }
}
