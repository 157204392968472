.historyMain {
  height: 100%;
  .historyPicContainer {
    height: calc(100% - 5rem);
    /* 图片展示区域布局 */
    display: grid;
    grid-template-columns: repeat(
      5,
      minmax(10rem, 1fr)
    ); /* 根据页面宽度自适应 n 列 */
    gap: 1.6rem; /* 图片之间的间距 */

    .image-grid {
      width: 100%;
      height: 24rem;
      cursor: pointer;
      overflow: hidden; /* 保证图片放大时不会超出容器范围 */
      position: relative;
      border-radius: 12px;

      &:hover {
        img {
          transform: scale(1.1); /* 鼠标悬浮时图片放大1.1倍 */
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover; /* 保持图片比例 */
        transition: transform 1s ease-in-out; /* 定义放大动画的过渡效果 */
      }
    }
  }

  .paginationContainer {
    margin: 1.6rem auto;
    display: table;
    & > .ant-pagination-item {
      border: 1px solid #999;
      border-radius: 8px;
    }

    & > .ant-pagination-item-active {
      border: transparent;
      background-color: #f30;
      color: #fff;
      a {
        color: #fff;
      }
    }

    & > .ant-pagination-prev {
      border: 1px solid #999;
      border-radius: 8px;
      padding: 0 0.6rem;
      a {
        color: #000;
      }
    }
    & > .ant-pagination-next {
      border: 1px solid #999;
      border-radius: 8px;
      padding: 0 0.6rem;
      a {
        color: #000;
      }
    }
  }
}
