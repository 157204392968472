.withdrawalReviewMain {
  padding: 4rem;
  height: 100%;
  box-sizing: border-box;
  .title {
    font-size: 2.4rem;
    line-height: 2.4rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .tabMain {
    display: flex;
    margin-bottom: 2rem;
    .tabItem {
      cursor: pointer;
      padding: 0 1rem;
      line-height: 3rem;
      margin-right: 2rem;
      color: #000;
      font-size: 1.4rem;
      transition: all 0.2s linear 0.1s;
    }

    .active {
      background-color: #f2f2f2;
      border-radius: 4px;
    }
  }

  .dataContainer {
    .optionBtnGroup {
      display: flex;
      .linkBtn {
        margin-right: 0.8rem;
        cursor: pointer;
        color: #0000ee;
        border-bottom: 1px solid #0000ee;
      }
    }
  }
}

.paginationContainer {
  margin: 1.6rem auto;
  display: table;
  & > .ant-pagination-item {
    border: 1px solid #999;
    border-radius: 8px;
  }

  & > .ant-pagination-item-active {
    border: transparent;
    background-color: #f30;
    color: #fff;
    a {
      color: #fff;
    }
  }

  & > .ant-pagination-prev {
    border: 1px solid #999;
    border-radius: 8px;
    padding: 0 0.6rem;
    a {
      color: #000;
    }
  }
  & > .ant-pagination-next {
    border: 1px solid #999;
    border-radius: 8px;
    padding: 0 0.6rem;
    a {
      color: #000;
    }
  }
}

.modalBtn {
  margin: 0 auto;
  height: 5rem;
  width: 10rem;
  background-color: #f30;
  &:hover {
    background-color: #f30 !important;
  }
}
