.mainContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  .windowMask {
    width: 100%;
    height: 100%;
    background-color: #0009;
    z-index: 999;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    .windowMaskLoading {
      width: 20rem;
    }
  }
  .mainContainerContent {
    flex: 1;
    overflow-y: auto;
    height: calc(100% - 8rem);
  }
}
