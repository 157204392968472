.upLoadMain {
  height: calc(100% - 10rem);
  background-color: #ffffff;
  padding: 4rem 5rem;
  border-radius: 10px;
  .tabMain {
    display: flex;
    justify-content: space-between;
    .tabMainLeft {
      display: flex;
      .tabItem {
        cursor: pointer;
        padding: 0 1rem;
        line-height: 3rem;
        margin-right: 2rem;
        color: #000;
        font-size: 1.4rem;
        transition: all 0.2s linear 0.1s;
      }

      .active {
        background-color: #f2f2f2;
        border-radius: 4px;
      }
    }

    .searchComp {
      background: #f5f5f5;
      border-radius: 32px;
      display: flex;
      .searchCompInput {
        height: 4.2rem !important;
        background: transparent !important;
        border: none !important;
        border-radius: 32px !important;
        font-size: 1.6rem !important;
        color: #333 !important;
        box-shadow: unset !important;
        font-weight: 600;
        &::placeholder {
          color: #b9b9b9 !important;
        }
      }

      .searchCompBtn {
        cursor: pointer;
        display: block;
        width: 1.6rem;
        height: 1.6rem;
        margin: auto 8px auto 0;
      }
    }
  }

  .dataContainer {
    .dataItem {
      display: flex;
      justify-content: space-between;
      padding: 2rem 2rem 2rem 0;
      border-bottom: 1px solid rgba(224, 224, 224, 0.7);
      cursor: pointer;
      .itemLeft {
        display: flex;
        .itemPic {
          width: 28rem;
          height: 21rem;
          margin-right: 4rem;
          border-radius: 12px;
        }

        .itemMessage {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .itemMessageTop {
            .itemTitle {
              font-size: 1.6rem;
              font-weight: 600;
              margin-bottom: 2.4rem;
            }

            .itemSubTitle {
              margin-bottom: 2.4rem;
              .itemSubTitleRow {
                display: flex;
                color: #999;
                font-size: 1.4rem;
                margin-bottom: 1.2rem;
                .itemMessageTopMiddleItem {
                  margin-right: 0.8rem;
                }
              }
            }

            .itemPrice {
              color: #999;
              font-size: 1.4rem;
              margin-bottom: 2.4rem;
            }

            .itemTime {
              color: #999;
              font-size: 1.4rem;
              margin-bottom: 2.4rem;
            }
          }

          .itemMessageBottom {
            display: flex;
            .itemStatus {
              font-size: 1.4rem;
              margin-right: 3rem;
              line-height: 1.4rem;
            }

            .status1 {
              color: #d39e00;
            }
            .status2 {
              color: #28a745;
            }
            .status3 {
              color: #f30;
            }
            .status4 {
              color: #999;
            }

            .tips {
              cursor: pointer;
              font-size: 1.4rem;
              color: #f30;
              display: flex;
              line-height: 1.4rem;
              .tipsIcon {
                display: block;
                width: 1.4rem;
                height: 1.4rem;
                margin-right: 0.4rem;
              }
            }
          }
        }
      }

      .itemRight {
        display: flex;
        font-size: 1.4rem;
        height: 1.4rem;
        line-height: 1.4rem;
        .itemRightBtn {
          cursor: pointer;
          color: #333;
        }
        .itemRightBtnRed {
          cursor: pointer;
          color: #f30;
        }

        .itemRightBtnSplitLine {
          height: 1.6rem;
          line-height: 1.6rem;
          margin: 0 1rem;
          width: 2px;
          background-color: #999;
        }
      }
    }
  }

  .paginationContainer {
    margin: 7rem auto 2rem auto;
    display: table;
    & > .ant-pagination-item {
      border: 1px solid #999;
      border-radius: 8px;
    }

    & > .ant-pagination-item-active {
      border: transparent;
      background-color: #f30;
      color: #fff;
      a {
        color: #fff;
      }
    }

    & > .ant-pagination-prev {
      border: 1px solid #999;
      border-radius: 8px;
      padding: 0 0.6rem;
      a {
        color: #000;
      }
    }
    & > .ant-pagination-next {
      border: 1px solid #999;
      border-radius: 8px;
      padding: 0 0.6rem;
      a {
        color: #000;
      }
    }
  }
}
