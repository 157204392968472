.tabConfig {
  padding: 4rem;
  height: 100%;
  box-sizing: border-box;
  .tabConfigTitle {
    font-size: 2.4rem;
    line-height: 2.4rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .tabConfigMain {
    height: calc(100% - 2.4rem);
    display: flex;
    justify-content: space-around;
    .tabConfigMainLeft {
      .configItem {
        margin-bottom: 2rem;
        display: flex;
        line-height: 3rem;
        height: 3rem;
        .configLabel {
          font-size: 1.4rem;
        }

        .configIcon {
          width: 2rem;
          height: 2rem;
          cursor: pointer;
          margin-right: 2rem;
          display: inline-block;
          vertical-align: middle;
        }

        .configInputContainer {
          width: 40%;
          margin-right: 2rem;
          .configInput {
            width: 76%;
          }
          .configInputBtn {
            width: 24%;
          }
        }

        .configIconBtn {
          margin-right: 1rem;
        }

        .selectedBtn {
          color: #f30;
          border-color: #f30;
        }
      }
    }

    .tabConfigSplitLine {
      height: 100%;
      width: 2px;
      background-color: #999;
    }

    .tabConfigMainRight {
      .subConfigItem {
        margin-bottom: 2rem;
        display: flex;
        line-height: 3rem;
        height: 3rem;
        .subConfigLabel {
          font-size: 1.4rem;
        }

        .subConfigIcon {
          width: 2rem;
          height: 2rem;
          cursor: pointer;
          margin-right: 2rem;
          display: inline-block;
          vertical-align: middle;
        }

        .subConfigInputContainer {
          width: 40%;
          margin-right: 2rem;
          .subConfigInput {
            width: 76%;
          }
          .subConfigInputBtn {
            width: 24%;
          }
        }

        .subConfigIconBtn {
          margin-right: 1rem;
        }

        .selectedBtn {
          color: #f30;
          border-color: #f30;
        }
      }
    }
  }
}
