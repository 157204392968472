.payMain {
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;
  .payContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .payContainerCenter {
      .payHeader {
        display: flex;
        justify-content: center;
        margin-bottom: 40%;
        .headerLogo {
          display: block;
          width: 10rem;
          height: 10rem;
        }
        .headerTitle {
          display: block;
          font-size: 8rem;
          line-height: 10rem;
          font-weight: 600;
        }
      }

      .payTitle {
        text-align: center;
        font-size: 6rem;
        color: #333;
        font-weight: 600;
        margin-bottom: 30%;
      }

      .payStatusIcon {
        width: 14rem;
        height: 14rem;
        display: block;
        margin: 0 auto;
      }

      .payStatusText {
        margin-top: 8%;
        font-size: 5rem;
        font-weight: 500;
        text-align: center;
      }

      .priceContainer {
        text-align: center;
        font-size: 12rem;
        color: #333;
        font-weight: 600;
        margin-bottom: 30%;
        .priceIcon {
          font-size: 8rem;
        }
      }
      .priceContainerSmall {
        font-size: 8rem !important;
      }

      .btnClass {
        margin: 0 auto;
        height: 10rem;
        font-size: 4rem;
        width: 100%;
        color: #fff;
        background-color: #f30;
        &:hover {
          background-color: #f30 !important;
        }
      }

      .btnClassDisabled {
        background-color: #999;
        &:hover {
          background-color: #999 !important;
        }
      }
    }
  }
}
