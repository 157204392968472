.adminLayout {
  width: calc(100% - 16rem);
  padding: 4rem 8rem;
  background-color: #f6f6f6;
  display: flex;
  // height: calc(100% - 20.6rem);
  min-height: 65rem;
  justify-content: space-between;
  .sidebar {
    width: 10%;
    flex-shrink: 0;
    .sidebarTop {
      margin-bottom: 1.8rem;
      .headerLogo {
        width: 10rem;
        height: 10rem;
        display: block;
        margin: 0 auto 1.4rem;
        border-radius: 50%;
      }

      .userId {
        color: #333;
        height: 1.6rem;
        line-height: 1.6rem;
        margin-bottom: 1.2rem;
        font-size: 1.4rem;
        cursor: default;
        text-align: center;
        font-weight: 700;
      }
    }

    .sidebarNav {
      .collect {
        cursor: pointer;
        display: flex;
        color: #333;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        height: 6rem;
        line-height: 6rem;
        border-bottom: 1px solid #e9e9e9;
        font-size: 1.4rem;
        .linkItemIcon {
          width: 2.4rem;
          height: 2.4rem;
          margin-right: 1rem;
          background-repeat: no-repeat;
          background-image: url("../../assets/spritPic.png");
          background-position: -19px -19px;
        }
        &:hover {
          color: #f30;
          .linkItemIcon {
            background-position: -19px -49px;
          }
        }
      }

      .collected {
        color: #f30;
        .linkItemIcon {
          background-position: -19px -49px;
        }
      }

      .download {
        cursor: pointer;
        display: flex;
        color: #333;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        height: 6rem;
        line-height: 6rem;
        border-bottom: 1px solid #e9e9e9;
        font-size: 1.4rem;
        .linkItemIcon {
          width: 2.4rem;
          height: 2.4rem;
          margin-right: 1rem;
          background-repeat: no-repeat;
          background-image: url("../../assets/spritPic.png");
          background-position: -49px -19px;
        }
        &:hover {
          color: #f30;
          .linkItemIcon {
            background-position: -49px -49px;
          }
        }
      }

      .downloaded {
        color: #f30;
        .linkItemIcon {
          background-position: -49px -49px;
        }
      }

      .publish {
        cursor: pointer;
        display: flex;
        color: #333;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        height: 6rem;
        line-height: 6rem;
        border-bottom: 1px solid #e9e9e9;
        font-size: 1.4rem;
        .linkItemIcon {
          width: 2.4rem;
          height: 2.4rem;
          margin-right: 1rem;
          background-repeat: no-repeat;
          background-image: url("../../assets/spritPic.png");
          background-position: -79px -19px;
        }
        &:hover {
          color: #f30;
          .linkItemIcon {
            background-position: -79px -49px;
          }
        }
      }

      .published {
        color: #f30;
        .linkItemIcon {
          background-position: -79px -49px;
        }
      }

      .upload {
        cursor: pointer;
        display: flex;
        color: #333;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        height: 6rem;
        line-height: 6rem;
        border-bottom: 1px solid #e9e9e9;
        font-size: 1.4rem;
        .linkItemIcon {
          width: 2.4rem;
          height: 2.4rem;
          margin-right: 1rem;
          background-repeat: no-repeat;
          background-image: url("../../assets/spritPic.png");
          background-position: -109px -19px;
        }
        &:hover {
          color: #f30;
          .linkItemIcon {
            background-position: -109px -49px;
          }
        }
      }

      .uploaded {
        color: #f30;
        .linkItemIcon {
          background-position: -109px -49px;
        }
      }

      .profit {
        cursor: pointer;
        display: flex;
        color: #333;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        height: 6rem;
        line-height: 6rem;
        border-bottom: 1px solid #e9e9e9;
        font-size: 1.4rem;
        .linkItemIcon {
          width: 2.4rem;
          height: 2.4rem;
          margin-right: 1rem;
          background-repeat: no-repeat;
          background-image: url("../../assets/spritPic.png");
          background-position: -139px -19px;
        }
        &:hover {
          color: #f30;
          .linkItemIcon {
            background-position: -139px -49px;
          }
        }
      }

      .profited {
        color: #f30;
        .linkItemIcon {
          background-position: -139px -49px;
        }
      }

      .personal {
        cursor: pointer;
        display: flex;
        color: #333;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        height: 6rem;
        line-height: 6rem;
        border-bottom: 1px solid #e9e9e9;
        font-size: 1.4rem;
        .linkItemIcon {
          width: 2.4rem;
          height: 2.4rem;
          margin-right: 1rem;
          background-repeat: no-repeat;
          background-image: url("../../assets/spritPic.png");
          background-position: -198px -19px;
        }
        &:hover {
          color: #f30;
          .linkItemIcon {
            background-position: -198px -49px;
          }
        }
      }

      .personaled {
        color: #f30;
        .linkItemIcon {
          background-position: -198px -49px;
        }
      }

      .history {
        cursor: pointer;
        display: flex;
        color: #333;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        height: 6rem;
        line-height: 6rem;
        border-bottom: 1px solid #e9e9e9;
        font-size: 1.4rem;
        .linkItemIcon {
          width: 2.4rem;
          height: 2.4rem;
          margin-right: 1rem;
          background-repeat: no-repeat;
          background-image: url("../../assets/spritPic.png");
          background-position: -229px -19px;
        }
        &:hover {
          color: #f30;
          .linkItemIcon {
            background-position: -229px -49px;
          }
        }
      }

      .historyed {
        color: #f30;
        .linkItemIcon {
          background-position: -229px -49px;
        }
      }

      .setting {
        cursor: pointer;
        display: flex;
        color: #333;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        height: 6rem;
        line-height: 6rem;
        border-bottom: 1px solid #e9e9e9;
        font-size: 1.4rem;
        .linkItemIcon {
          width: 2.4rem;
          height: 2.4rem;
          margin-right: 1rem;
          background-repeat: no-repeat;
          background-image: url("../../assets/spritPic.png");
          background-position: -294px -19px;
        }
        &:hover {
          color: #f30;
          .linkItemIcon {
            background-position: -294px -49px;
          }
        }
      }

      .settinged {
        color: #f30;
        .linkItemIcon {
          background-position: -294px -49px;
        }
      }
    }
  }

  .content {
    width: 85%;
    height: 100%;
  }
}
