.loginMain {
  .loginTitle {
    font-size: 2.6rem;
    font-weight: 700;
    color: #333;
    cursor: default;
  }

  .loginNoAccount {
    font-size: 1.4rem;
    color: #999;
    margin-top: -2.2rem;
    margin-bottom: 2.8rem;
    display: flex;
    cursor: default;
    .loginNoAccountSpec {
      color: #333;
      cursor: pointer;
      &:hover {
        color: #f30;
      }
    }
  }

  .loginPicContainer {
    position: relative;
    width: 16rem;
    height: 16rem;
    display: block;
    text-align: center;
    margin: 0 auto 4rem auto;
    .loginPic {
      width: 100%;
      height: 100%;
    }
    .loginPicInvalid {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      background-color: #0009;
      .invalidText {
        font-size: 18px;
        color: #fff;
        cursor: default;
      }

      .invalidBtn {
        cursor: pointer;
        width: 8.8rem;
        height: 3rem;
        text-align: center;
        line-height: 3rem;
        color: #fff;
        margin-top: 2rem;
        border-radius: 4px;
        background-color: #3bc66f;
      }
    }
  }

  .loginPicTitle {
    cursor: default;
    display: flex;
    justify-content: center;
    .loginPicIcon {
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 0.4rem;
    }

    .loginPicText {
      font-size: 1.4rem;
    }
  }

  .loginInput {
    margin-bottom: 1.6rem;
    width: 100%;
    height: 5rem;
    font-size: 1.4rem;
    color: #333;
    text-indent: 2rem;
    background-color: #fff;
    border-radius: 10px;
    border: solid 1px #dddddd;
    &::placeholder {
      color: #999 !important;
    }
    &:focus {
      border: solid 1px #333;
      box-shadow: none !important; /* 禁用默认的阴影效果 */
    }
  }

  .loginInputCode {
    height: 5rem;
    margin-bottom: 1.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .code-input {
      width: 60%;
      display: block;
      height: 5rem;
      font-size: 1.4rem;
      color: #333;
      text-indent: 2rem;
      background-color: #fff;
      border-radius: 10px;
      border: solid 1px #dddddd;
      &::placeholder {
        color: #999 !important;
      }
      &:focus {
        border: solid 1px #333;
        box-shadow: none !important; /* 禁用默认的阴影效果 */
      }
    }

    .send-code-button {
      height: 5rem;
      width: 38%;
      display: block;
    }
  }

  .forgetPassword {
    text-align: right;
    color: inherit;
    cursor: pointer;
    margin-bottom: 1.6rem;
  }

  .loginBtn {
    width: 100%;
    height: 5rem;
    color: #f0f0f0;
    text-align: center;
    line-height: 5rem;
    border-radius: 10px;
    background: linear-gradient(90deg, #ff541f, #ff2323);
    margin-bottom: 2.2rem;
    cursor: pointer;
    &:hover {
      background: linear-gradient(90deg, #ff6917, #ff3000);
    }
  }

  .loginTypeBtnGroup {
    display: flex;
    justify-content: center;
    border-top: 1px solid #eee;
    padding-top: 2rem;
    margin-bottom: 2rem;
    margin-top: 3rem;
    .loginTypeBtn {
      display: flex;
      cursor: pointer;
      .loginTypeBtnIcon {
        width: 2.4rem;
        height: 2.4rem;
      }

      .loginTypeBtnText {
        margin-left: 1rem;
        display: flex;
        align-items: center;
        &:hover {
          color: #f30;
        }
      }
    }
  }

  .loginFooter {
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    color: #bbb;
    font-size: 1.4rem;
    margin-bottom: 2.8rem;
    cursor: default;
    display: flex;
    .footerLink {
      cursor: pointer;
    }
  }
}
