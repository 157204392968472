.publishContainer {
  .publishTopBtn {
    width: fit-content;
    padding: 0.8rem 1.6rem;
    margin-bottom: 4rem;
    display: flex;
    border: 1px solid #ff0000;
    color: red;
    border-radius: 10px;
    position: relative;
    font-size: 1.4rem;
    align-items: center;
    cursor: pointer;
    .btnIcon {
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 0.2rem;
      background-repeat: no-repeat;
      background-image: url("../../assets/spritPic.png");
      background-position: -56px -79px;
    }

    &:hover {
      border-color: transparent;
      background: linear-gradient(90deg, #ff541f 0%, #ff2323 100%);
      color: #fff;
      .btnIcon {
        background-position: -23px -79px;
      }
    }
  }

  .publishMain {
    height: calc(100% - 10rem);
    background-color: #ffffff;
    padding: 4rem 5rem;
    border-radius: 10px;
    & > .ant-form > .ant-form-item > .ant-row > .ant-col > label {
      color: #999 !important;
    }
    .publishFormMain {
      width: 90%;
      margin: 0 auto;
      .tagTip {
        color: rgb(204, 204, 204);
        margin-top: 1rem;
      }
      .upload-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem; // 控制图片与按钮之间的间距
        min-height: 23rem;

        .uploadContainer {
          width: 100% !important;
          & > .ant-upload {
            width: 100% !important;
          }
        }
        .uploadBtn {
          .uploadNoneBtn {
            width: 100% !important;
            padding: 5rem 0;
            text-align: center;
            border: 1px dashed #e4e4e4;
            background-color: #f6f6f6;
            cursor: pointer;
            border-radius: 10px;
            .uploadEmptyImg {
              width: 4.4rem;
              height: 3.4rem;
              margin: 0 auto 3rem auto;
              background-repeat: no-repeat;
              background-image: url("../../assets/spritPic.png");
              background-position: -235px -78px;
            }

            .btnTitle {
              margin-bottom: 2rem;
              color: #aeaeae;
              font-size: 1.6rem;
              text-align: center;
            }

            .subTitle {
              color: #d7d7d7;
              font-size: 1.4rem;
              text-align: center;
            }
          }
          .uploadedBtn {
            margin: 0 auto;
            // width: 200px;
            // height: 200px;
            .uploadEmptyImg {
              width: 4.4rem;
              height: 3.4rem;
              margin: 0 auto;
              background-repeat: no-repeat;
              background-image: url("../../assets/spritPic.png");
              background-position: -235px -78px;
            }
          }
        }
      }

      .upload-container > .ant-upload {
        margin-bottom: 0; // 保证上传按钮和图片对齐
      }

      .ant-upload-select {
        width: 200px;
        height: 200px;
      }

      .uploaded-image {
        position: relative;
        width: 200px;
        height: 200px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: move; // 鼠标悬停时显示拖拽手型
      }

      .uploaded-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .uploaded-image .close-icon {
        position: absolute;
        top: 5px;
        right: 5px;
        background: rgba(0, 0, 0, 0.6);
        color: #fff;
        border-radius: 50%;
        cursor: pointer;
      }

      .uploaded-image .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5); /* 灰色蒙版颜色 */
        display: flex;
        align-items: flex-end; /* 将子元素对齐到底部 */
        justify-content: center;
        opacity: 0; /* 初始状态透明 */
        transition: opacity 0.3s; /* 渐变效果 */
      }

      /* 鼠标悬浮时显示蒙版 */
      .uploaded-image:hover .overlay {
        opacity: 1;
      }

      /* 新增：设置为封面按钮 */
      .set-cover-button {
        background: #fff;
        color: #000;
        padding: 5px 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
        position: relative;
        text-align: center;
        margin-bottom: 20px; /* 为底部按钮提供间距 */
      }

      /* 鼠标悬停按钮时的样式 */
      .set-cover-button:hover {
        background: #000;
        color: #fff;
      }

      .tagItem {
        margin-bottom: 0.8rem;
      }

      .no-asterisk {
        height: 6rem;
        .checkbox {
          font-weight: 600;
          display: flex;
        }
      }
      /* 自定义类隐藏星号 */
      .no-asterisk .ant-form-item-required::before {
        display: none !important; /* 隐藏星号 */
      }
      .no-asterisk .ant-form-item-required::after {
        display: none !important; /* 隐藏冒号 */
      }
    }

    .loading-overlay {
      position: fixed; /* 固定定位 */
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5); /* 半透明遮罩层 */
      display: flex; /* 使用 Flexbox */
      align-items: center; /* 垂直居中 */
      justify-content: center; /* 水平居中 */
      z-index: 9999; /* 确保在最上层 */
    }
  }
}
