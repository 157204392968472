.aboutUsContainer {
  padding: 2rem 8rem;
  min-height: calc(100% - 16.5rem);
  position: relative;
  background-color: #f6f6f6;
  display: flex;
  .leftNav {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    flex-shrink: 0;
    margin-right: 2rem;
    height: fit-content;
    .navItem {
      text-align: center;
      padding: 2rem 3rem;
      border-radius: 8px;
      color: #333;
      font-size: 1.4rem;
      cursor: pointer;
      position: relative;
      &:hover {
        color: #fff;
        background-color: #f30;
      }
    }

    .selected {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 2px;
        height: 100%;
        background-color: #f30;
        transition: width 0.2s ease-in-out;
        z-index: 0;
      }
      background-color: #fff; /* 可选：选中行背景色 */
    }
  }

  .mainContainer {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    min-height: 80rem;
    .mainHeader {
      width: 100%;
      height: 7rem;
      line-height: 7rem;
      font-size: 1.6rem;
      color: #333;
      border-bottom: 2px solid #f6f6f6;
      padding: 0 3rem;
    }

    .mainContent {
      padding: 3rem;
      .text {
        color: #999;
        font-size: 1.4rem;
        line-height: 2.4rem;
        text-indent: 2rem;
        margin-bottom: 2rem;
      }
      .header1 {
        text-indent: 2rem;
        margin-bottom: 2rem;
        font-size: 1.6rem;
      }
      .textHighlight {
        color: #333;
        font-size: 1.4rem;
        line-height: 2.4rem;
        text-indent: 2rem;
        margin-bottom: 2rem;
      }
    }
  }
}
