.veModalFooter {
  display: "flex";
  justify-content: "center";
  .modalBtn {
    margin: 0 auto;
    height: 5rem;
    width: 10rem;
    background-color: #f30;
    &:hover {
      background-color: #f30 !important;
    }
  }
}

.vemodalContainer {
  padding: 2rem 2rem;
  .vemodalItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    .vemodalItemLabel {
      font-size: 1.4rem;
      font-weight: 500;
      flex-shrink: 0;
    }

    .vemodalItemInput {
      width: 60%;
    }
  }

  .vemodalItemNoWrap {
    .vemodalItemLabel {
      font-size: 1.4rem;
      font-weight: 500;
    }

    .vemodalItemUpload {
      margin-top: 2rem;
      display: flex;
      justify-content: space-around;
      .uploadTitle{
        text-align: center;
        margin-top: 2rem;
      }
      .vemodalItemUploadBtnContainer {
        width: 15rem;
        height: 10rem;
        .ant-upload {
          width: 15rem !important;
          height: 10rem !important;
        }
        .vemodalItemUploadBtn {
          width: 15rem !important;
          height: 10rem !important;
          border-radius: 10px;
          overflow: hidden;
          object-fit: cover;

          .uploadPic {
            width: 100%;
          }
        }
      }
    }
  }
}
